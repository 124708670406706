/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  //  useEffect,
  useState,
} from 'react';
import {
  Box,
  Typography,
  useTheme,
  // Collapse,
  Container,
  useMediaQuery,
} from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import { useTranslation } from 'next-i18next';
import {
  AdventuresOnCamera,
  //  CameraCaughtVideo,
} from 'views/common/components/UI/MotorcycleAdventuresCaughtOnCamera';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  StyledCarousel,
  StyledBox,
} from 'views/common/components/UI/MotorcycleAdventuresCaughtOnCamera/MotorcycleAdventuresCaughtOnCamera.styles';
import Typography500 from 'views/common/components/UI/Typography500';
import ScrollTo from 'lib/utils/ScrollTo';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';

const CameraCaughtVideoOverlay = dynamic(() => import('views/common/components/UI/MotorcycleAdventuresCaughtOnCamera').then(
  (file) => file.CameraCaughtVideoOverlay,
));

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1300 },
    items: 5,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },
  desktop: {
    breakpoint: { max: 1300, min: 1025 },
    items: 4,
    partialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { max: 1025, min: 600 },
    items: 2,
    partialVisibilityGutter: 20,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
    arrow: false,
  },
};

const ArrowLeft = (arrowLeftProps) => {
  const {
    handleArrowClick,
    onClick,
    carouselState: { currentSlide },
    ...rest
  } = arrowLeftProps;

  return (
    <span
      onClick={() => {
        handleArrowClick(currentSlide);
        onClick();
      }}
      {...rest}
      className="arrow arrowLeft">
      <ChevronLeftIcon />
    </span>
  );
};

const ArrowRight = (arrowRightProps) => {
  const {
    handleArrowClick,
    onClick,
    carouselState: { currentSlide },
    ...rest
  } = arrowRightProps;

  return (
    <span
      onClick={() => {
        handleArrowClick(currentSlide);
        onClick();
      }}
      {...rest}
      className="arrow arrowRight">
      <ChevronRightIcon />
    </span>
  );
};
const MotorcycleAdventuresCaughtOnCamera = (props) => {
  const { AdventuresOnCameraItems, sectionTitle, removeContainer } = props;
  const { t } = useTranslation(
    'fe_er_common_motorcycle_adventures_caught_on_camera',
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // const isDeskTopAndTablet = useMediaQuery(theme.breakpoints.up('md'));
  const [selectedItem, setSelectedItem] = useState({
    show: false,
    index: 0,
    item: AdventuresOnCameraItems[0],
  });
  const { isCrawler, deviceType } = useSelector((state) => state.globalData);
  const handelclick = (item, index, show) => {
    window.gtag('event', 'adventure_caught_click', {
      card_order: index + 1,
    });
    setSelectedItem({
      show,
      index,
      item,
      scroll: true,
    });
  };
  const handleClose = () => {
    setSelectedItem((prevValue) => ({ ...prevValue, show: false }));
    ScrollTo('moto_adventures');
  };

  const handleRightArrowClick = () => {
    const newIndex = (selectedItem.index + 1) % AdventuresOnCameraItems.length;
    setSelectedItem((prev) => ({
      ...prev,
      index: newIndex,
      item: AdventuresOnCameraItems[newIndex],
    }));
  };
  const handleLeftArrowClick = () => {
    const newIndex = (((selectedItem.index - 1) % AdventuresOnCameraItems.length)
        + AdventuresOnCameraItems.length)
      % AdventuresOnCameraItems.length;

    setSelectedItem((prev) => ({
      ...prev,
      index: newIndex,
      item: AdventuresOnCameraItems[newIndex],
    }));
  };
  // there is no need for this effect when use overlay
  // useEffect(() => {
  //   if (selectedItem.scroll) {
  //     ScrollTo('expand_item', -80);
  //     setSelectedItem({ ...selectedItem, scroll: false });
  //   }
  // }, [selectedItem]);

  return (
    <StyledBox
      className={`MACOCmotorcycleAdventures ${
        removeContainer && 'MACOCRemoveContainer'
      }`}>
      <Container maxWidth="xl">
        <Typography500 variant="h3" component="h2" className="MACOCmaintitle">
          {sectionTitle || (
            <>
              {t(
                'fe_er_common_motorcycle_adventures_caught_on_camera:motorcycle_adventures_caught_on_camera',
              )}
              <Typography
                variant="h3"
                component="span"
                className="MACOCitemNumbers showTabletAndDesktop">
                {selectedItem.index + 1}
                /
                {AdventuresOnCameraItems.length}
              </Typography>
            </>
          )}
        </Typography500>

        <Box className="MACCarouselWrapper">
          <StyledCarousel
            infinite
            arrows={!isCrawler && !isMobile}
            ssr={isCrawler}
            partialVisible
            customTransition="transform 200ms ease-in"
            deviceType={deviceType}
            autoPlay={false}
            shouldResetAutoplay={false}
            responsive={responsive}
            customLeftArrow={
              <ArrowLeft handleArrowClick={handleLeftArrowClick} />
            }
            customRightArrow={
              <ArrowRight handleArrowClick={handleRightArrowClick} />
            }
            // centerMode={isDeskTopAndTablet}
          >
            {AdventuresOnCameraItems.map((item, index) => (
              <Box
                className="CarouselItem"
                key={item.id}
                onClick={() => handelclick(item, index, true)}>
                <AdventuresOnCamera
                  title={item.title}
                  subTitle={item.subTitle}
                  bgImg={item.bgImg}
                  path={item.path}
                  active={
                    selectedItem?.show && selectedItem?.item?.id === item.id
                  }
                  pathLable={item.pathLable}
                />
              </Box>
            ))}
          </StyledCarousel>
        </Box>
        {/* -------------- hide  couse of replace It  with overlay--------- */}
        {/* <div id="expand_item">
          <Collapse in={!!selectedItem?.show} timeout="auto" unmountOnExit>
            {selectedItem && (
              <CameraCaughtVideo
                title={selectedItem.item.title}
                subTitle={selectedItem.item.subTitle}
                description={selectedItem.item.description}
                path={selectedItem.item.path}
                pathLable={selectedItem.item.pathLable}
                videoId={selectedItem.item.videoId}
                handleClose={handleClose}
                handleRightArrowClick={handleRightArrowClick}
                handleLeftArrowClick={handleLeftArrowClick}
              />
            )}
          </Collapse>
        </div> */}
      </Container>
      <CameraCaughtVideoOverlay
        open={!!selectedItem?.show}
        handleClose={handleClose}
        title={selectedItem.item.title}
        subTitle={selectedItem.item.subTitle}
        description={selectedItem.item.description}
        path={selectedItem.item.path}
        pathLable={selectedItem.item.pathLable}
        videoId={selectedItem.item.videoId}
        handleClickRelatedVideosSelect={handelclick}
      />
    </StyledBox>
  );
};

export { MotorcycleAdventuresCaughtOnCamera };
