import React from 'react';
import {
  Skeleton, Card, Box, Grid,
} from '@mui/material';

const RewardSkeleton = () => (
  <Card>
    <Box p={3}>
      <Skeleton variant="text" width="250px" height={60} />
      <Grid container spacing={{ lg: 3, md: 0 }}>
        <Grid item lg={8} md={12} sm={12}>
          <Skeleton variant="text" width="80%" height={40} />
        </Grid>
        <Grid item lg={2} md={12} sm={12}>
          <Skeleton variant="text" width="100%" height={40} />
        </Grid>
        <Grid item lg={2} md={12} sm={12}>
          <Skeleton variant="text" width="100%" height={40} />
        </Grid>
      </Grid>
    </Box>
  </Card>
);
export { RewardSkeleton };
