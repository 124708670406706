import React from 'react';
import {
  Grid, Container, useTheme, useMediaQuery,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const MotorcycleAdventuresCaughtOnCameraSkelton = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const getnumber = () => {
    let n;
    if (isTablet && !isMobile) {
      n = 3;
    } else if (isTablet && isMobile) {
      n = 1;
    } else {
      n = 5;
    }
    return n;
  };
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Skeleton width="40%" height={80} variant="text" />
        </Grid>
        <Grid container spacing={1.5} md={12} lg={12}>
          {[...Array(getnumber())].map(() => (
            <Grid item xs={12} md={4} lg>
              <Skeleton width="100" height={400} variant="rectangular" />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};
export { MotorcycleAdventuresCaughtOnCameraSkelton };
