import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImgBox } from 'views/common/components';

export const StyledBox = styled(Box)(({ theme }) => ({
  '&.TourPackage': {
    position: 'relative',
    overflow: 'hidden',
    transition: 'all .7s ease',
    height: theme.spacing(41),
    width: '100%',
    borderRadius: theme.spacing(2.5),
    backgroundSize: 'cover',
    color: theme.palette.common.white,
    [theme.breakpoints.down('lg')]: {
      height: theme.spacing(35.125),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(30),
    },
    '& .textContainer': {
      padding: theme.spacing(0, 2),
      textAlign: 'center',
      position: 'absolute',
      left: 0,
      top: theme.spacing(7.5),
      width: '100%',
      zIndex: 1,
      '& svg': {
        width: theme.spacing(10.25),
        height: theme.spacing(10.25),
        [theme.breakpoints.down('md')]: {
          width: theme.spacing(7.25),
          height: theme.spacing(7.25),
        },
      },
      '& .MuiTypography-h3': {
        margin: theme.spacing(1, 0),
      },
    },
    '& .overlay': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 0,
      transition: 'all .7s ease',
      background:
        'linear-gradient(to bottom,rgba(207,207,207,0) 10%,rgba(0,0,0,0.9) 90%,rgba(0,0,0,1) 100%) no-repeat center center',
      borderRadius: theme.spacing(2.5),
    },
    '&:hover': {
      cursor: 'pointer',
      '& .overlay': {
        overflow: 'hidden',
        background:
          'linear-gradient(to bottom,rgba(207,207,207,0) 10%,rgba(0,0,0,0.8) 60%,rgba(0,0,0,1) 100%) no-repeat center center',
      },
      '& .TPTitle': {
        letterSpacing: 3,
      },
      '& .IBimgbox': {
        transform: 'scale(1.25)',
      },
    },
    '& .TPTitle': {
      fontSize: theme.spacing(2.5),
      transition: 'all .7s ease',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.spacing(2.25),
      },
    },
    '& .TPSubTitle': {
      color: theme.palette.warning.main,
    },
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '&.IBimgbox': {
    zIndex: -1,
    borderRadius: theme.spacing(2.5),
    transition: 'all .7s ease',
  },
}));
