import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';

import PATHS from 'lib/navigation/paths';

import Bike from 'views/common/components/UI/Icons/Bike';
import Map from 'views/common/components/UI/Icons/Map';
import { TourPackageNewVersion } from 'views/common/components/UI/TourPackageNewVersion';
import CustomTripDialog from 'views/common/components/Logical/Layout/MenuBar/CustomTripDialog';
import { SliderSectionContiner } from 'views/common/components';
import ClubMembership from 'views/common/components/UI/Icons/ClubMembership';
import SelfGuidedToursV2 from 'views/common/components/UI/Icons/SelfGuidedToursV2';

const RentalsAndToursPackagesNewVersion = () => {
  const { t } = useTranslation(
    'fe_er_common_renrals_and_tours_packages_section',
  );
  const showMembership = useSelector(
    (state) => state.globalData.showMembership,
  );

  const [openCustomTripsDialog, setOpenCustomTripsDialog] = useState(false);
  const handleCustomTripsClick = (e) => {
    e.preventDefault();
    setOpenCustomTripsDialog(true);
  };
  const handleCustomTripsDialogClose = () => {
    setOpenCustomTripsDialog(false);
  };

  const buttons = [
    {
      text: t(
        'fe_er_common_renrals_and_tours_packages_section:custom_trips_groups',
      ),
      onClick: handleCustomTripsClick,
    },
  ];

  const getTourPackageItems = () => {
    const tourPackageItems = [
      {
        id: 1,
        title: t(
          'fe_er_common_renrals_and_tours_packages_section:motorcycle_rentals',
        ),
        BGImage: '/imgs/tourPackage1.jpg',
        icon: <Bike />,
        path: PATHS.motorcycleRental(),
      },
      {
        id: 2,
        title: t(
          'fe_er_common_renrals_and_tours_packages_section:guided_motorcycle_tours',
        ),
        BGImage: '/imgs/tourPackage2.jpg',
        icon: <Map />,
        path: PATHS.tour.guidedTourIndex(),
      },
      {
        id: 3,
        title: t(
          'fe_er_common_renrals_and_tours_packages_section:self_guided_tours',
        ),
        BGImage: '/imgs/tourPackage3.jpg',
        icon: <SelfGuidedToursV2 />,
        path: PATHS.tour.selfTourIndex(),
      },
    ];
    const tourPackageItemsForMembership = [
      {
        id: 1,
        title: t(
          'fe_er_common_renrals_and_tours_packages_section:motorcycle_rentals',
        ),
        BGImage: '/imgs/tourPackage1.jpg',
        icon: <Bike />,
        path: PATHS.motorcycleRental(),
      },
      {
        id: 2,
        title: t(
          'fe_er_common_renrals_and_tours_packages_section:motorcycle_tours',
        ),
        BGImage: '/imgs/tourPackage2.jpg',
        icon: <Map />,
        path: PATHS.motorcycleTours.index(),
      },
      {
        id: 3,
        title: t(
          'fe_er_common_renrals_and_tours_packages_section:club_eaglerider_membership',
        ),
        subTitle: t(
          'fe_er_common_renrals_and_tours_packages_section:save_up_to',
        ),
        BGImage: '/imgs/tourPackage3.jpg',
        icon: <ClubMembership />,
        path: PATHS.motorcycleClub.index(),
      },
    ];
    return showMembership ? tourPackageItemsForMembership : tourPackageItems;
  };

  return (
    <>
      <SliderSectionContiner
        title={t(
          'fe_er_common_renrals_and_tours_packages_section:ways_to_travel_with_eaglerider',
        )}
        component="h2"
        buttons={buttons}
        deskTopItems={3}
        carouselArrow={false}>
        {getTourPackageItems().map((item) => (
          <Box className="CarouselItem" key={item.id}>
            <TourPackageNewVersion
              title={item.title}
              subTitle={item.subTitle}
              BGImage={item.BGImage}
              icon={item.icon}
              path={item.path}
            />
          </Box>
        ))}
      </SliderSectionContiner>
      <CustomTripDialog
        open={openCustomTripsDialog}
        onClose={handleCustomTripsDialogClose}
      />
    </>
  );
};
export { RentalsAndToursPackagesNewVersion };
