import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImgBox } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(7, 6),
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
  color: theme.palette.secondary.main,
  '& .AAAppAD': {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },

  '& .AAdesc': {
    color: theme.palette.secondary.light,
  },
  '& .AAdownloadBtns': {
    '& .MuiLink-root': {
      display: 'inline-block',
    },
  },

  '& .AAScan': {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2.75),
    width: theme.spacing(21.25),
    borderRadius: '2px',
    '&:after': {
      content: '""',
      width: theme.typography.pxToRem(13),
      borderTop: `13px solid ${theme.palette.primary.main}`,
      borderRight: '9px solid transparent',
      borderLeft: '9px solid transparent',
      right: 'calc(50% - 7.5px)',
      bottom: theme.typography.pxToRem(-9),
      position: 'absolute',
    },
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
      verticalAlign: 'sub',
    },
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '&.IBimgbox': {
    '&.AAQRCode': {
      margin: 'auto',
      border: `5px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.down('md')]: {
        height: theme.spacing(20),
        width: theme.spacing(20),
      },
    },
    '&.AADownload': {
      [theme.breakpoints.down('md')]: {
        height: theme.spacing(6.5),
        width: theme.spacing(22.5),
      },
    },
  },
}));

export default StyledBox;
