import React from 'react';
import { Box, Typography } from '@mui/material';
import AdventuresOnCameraStyledBox from 'views/common/components/UI/MotorcycleAdventuresCaughtOnCamera/AdventuresOnCamera/AdventuresOnCamera.styles';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Typography500 from 'views/common/components/UI/Typography500';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { useTranslation } from 'next-i18next';
import { ImgBox } from 'views/common/components';

const AdventuresOnCamera = (props) => {
  const {
    title, subTitle, bgImg, active, overlayCard,
  } = props;
  const { t } = useTranslation(
    'fe_er_common_motorcycle_adventures_caught_on_camera',
  );

  return (
    <AdventuresOnCameraStyledBox
      className={`AOCadventuresCard ${active ? 'active' : ''} ${
        overlayCard && 'AOCOverlayCard'
      }`}>
      <ImgBox
        image={{ src: bgImg, alt: 'Adventures on camera eagle rider image' }}
        imgHeight="100%"
      />
      <PlayCircleFilledWhiteIcon className="AOCplayIcon activeAndHover" />
      <PlayCircleOutlineIcon className="AOCplayIcon deActiveUnHover" />
      <Box className="AOCdetailsContainer">
        <Typography500 className="AOCDetailsTitle" variant="h4" component="p">
          {title}
        </Typography500>
        <Typography variant="subtitle2" component="p">
          {subTitle}
        </Typography>
        <Box className="AOCwatchNow">
          <PlayArrowIcon />

          <Typography500 variant="body2" component="span">
            {t('fe_er_common_motorcycle_adventures_caught_on_camera:watch_now')}
          </Typography500>
        </Box>
      </Box>
      <Box className="AOCoverlay" />
      {/* hide tringle couse of replace camera caught video with overlay */}
      {/* {active && <Box className="AOCtriangle" />} */}
    </AdventuresOnCameraStyledBox>
  );
};

export { AdventuresOnCamera };
